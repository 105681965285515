import classes from 'App.module.scss';

// hooks
import { useState, useEffect, useCallback } from 'react';
import { useWallet } from 'contexts/Wallet';

// libraries
import { toast } from 'react-toastify';

// components
import { NavBar } from 'components/NavBar';
import Card from 'components/Card';

// helpers
import Web3 from 'helpers/web3';
import Contracts from 'helpers/contracts';
import { getDate } from 'helpers/utils';

function App() {
  const { account, isConnectedToAllowedNetwork, refresh } = useWallet();

  const [stakingBalance, setStakingBalance] = useState('0.00');
  const [stakedAmount, setStakedAmount] = useState('0.00');
  const [earnedBalance, setEarnedBalance] = useState('0.00');
  const [stakingPeriod, setStakingPeriod] = useState('0.00');
  const [rewardDate, setRewardDate] = useState<string | number>();
  const [hasWithdrawn, setHasWithdrawn] = useState(false);
  const [tvl, setTVL] = useState('0.00');

  const [isStakingMode, setIsStakingMode] = useState(true);
  useEffect(() => {
    if (rewardDate && Date.now() > rewardDate) setIsStakingMode(false);
  }, [rewardDate]);

  const resetUserData = () => {
    setStakingBalance('0.00');
    setStakedAmount('0.00');
    setEarnedBalance('0.00');
  };

  const resetCommonData = () => {
    setRewardDate(getDate(new Date(0)));
    setStakingPeriod('0');
  };

  const fetchUserData = useCallback(async () => {
    if (!(await isConnectedToAllowedNetwork())) return resetUserData();

    const web3 = Web3.instance;
    const { Token, StakingRewards } = Contracts.instances;

    try {
      const [_stakingBalance, _stakedAmount, _earnedBalance, _hasWithdrawn] = await Promise.all([
        await web3.utils.fromWei(await Token.methods.balanceOf(account).call()),
        await web3.utils.fromWei(await StakingRewards.methods.getStaked(account).call()),
        await web3.utils.fromWei(await StakingRewards.methods.earned(account).call()),
        await StakingRewards.methods.withdrawn(account).call(),
      ]);
      setStakingBalance(parseFloat(_stakingBalance).toFixed(2));
      setStakedAmount(parseFloat(_stakedAmount).toFixed(2));
      setEarnedBalance(parseFloat(_earnedBalance).toFixed(2));
      setHasWithdrawn(_hasWithdrawn);
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong while fetching data.');
    }
  }, [account, isConnectedToAllowedNetwork]);

  const fetchCommonData = useCallback(async () => {
    if (!(await isConnectedToAllowedNetwork())) return resetCommonData();
    const web3 = Web3.instance;

    try {
      const { StakingRewards } = Contracts.instances;
      const _stakingPeriod = parseInt(await StakingRewards.methods.duration().call()) / 86400;
      const _rewardDate = parseInt(await StakingRewards.methods.stopTime().call()) * 1000;
      const _tvl = await web3.utils.fromWei(await StakingRewards.methods.getStakingTokenBalance().call());
      setTVL(parseFloat(_tvl).toLocaleString());
      setRewardDate(_rewardDate);
      setStakingPeriod(_stakingPeriod.toFixed(2));
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong while fetching data.');
    }
  }, [isConnectedToAllowedNetwork]);

  useEffect(() => {
    fetchCommonData();
    account ? fetchUserData() : resetUserData();
  }, [account, fetchCommonData, fetchUserData, refresh.triggerValue]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <NavBar />
      <div className={classes.content}>
        <Card
          tvl={tvl}
          stakingBalance={stakingBalance}
          stakedAmount={stakedAmount}
          earnedBalance={earnedBalance}
          stakingPeriod={stakingPeriod}
          rewardDate={rewardDate}
          hasWithdrawn={hasWithdrawn}
          stakingMode={isStakingMode}
        />
      </div>
    </div>
  );
}

export default App;
